<template>
    <div>
        <a-form :label-col="labelColEdit" :wrapper-col="wrapperColEdit">
            <a-form-item required name='name' label="指标分类名称" :rules="[{ required: true, message: '指标分类名称不能为空',tagger:'blur' }]">
                <a-input v-model:value="fromData.name" placeholder="请输入指标分类名称" />
            </a-form-item>
            <a-form-item label="上级分类">
                <a-tree-select :replaceFields="{children:'children', label:'title', value: 'id' }" v-model:value="fromData.pid" style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择" tree-default-expand-all allow-clear :tree-data="classifyListData">
                </a-tree-select>
            </a-form-item>
            <a-form-item label="所属产品">
                <a-select v-model:value="materialData" placeholder="请选择" mode="multiple" allow-clear :options="materialList"></a-select>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>

export default({
    props: {
		materialList:{
			type:Array,
			default:()=>{
				return []
			}
		},
        classifyListData:{
			type:Array,
			default:()=>{
				return []
			}
		},
		classifyFromData:{
			type: Object,
			default() {
			    return {}
			}
		},
    },
    data() {
        return {
            fromData:{},
            materialData:[],

            labelColEdit: {
                span: 6
            },
            wrapperColEdit: {
                span: 16
            },
        };
    },
	watch: {
		classifyFromData: {
			handler(newValue, oldValue){
				this.fromData = newValue
                this.getMaterialData(this.fromData.goods_related)
			},
		}
	},
    created() {
        this.fromData = this.classifyFromData
        this.getMaterialData(this.fromData.goods_related)
    },
    methods:{
        getMaterialData(info){
            let materialData = []
            if(info){
                if(info.indexOf(',') > -1){
                    materialData = info.split(',').map(item => parseInt(item))
                }else{
                    materialData.push(parseInt(info))
                }
            }
            this.materialData = materialData
        },
        getSubmit(){
            this.fromData.goods_related = this.materialData.length > 0 ? this.materialData.join(',') : ''
            return new Promise((resolve, reject) => {
                resolve(this.fromData)
            })
        },
    }
})
</script>